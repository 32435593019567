import React, { useCallback, useEffect, useRef, useState } from 'react';
import Styles from './EditTicketsView.module.css';
import { Dropdown, Input, SearchIcon, Flex, DropdownProps } from '@fluentui/react-northstar';
import { TicketCard } from '../TicketCard/TicketCard';
import { listInputItems } from '../../../tikit/ticketHelper';
import _ from 'lodash';
import { EmptyData } from '../EmptyData';
import FlashlightSVG from './../../../../svg/flashlight.svg';
import { TicketCardSkeleton } from '../TicketCard/TicketCardSkeleton';
import { useTranslation } from 'react-i18next';

interface Props {
  onTicketClick: (ticketId: number) => void;
  selectedTicketId: number;
  activeDrodpdownIndex: number;
  setActiveDrodpdownIndex: (index: number) => void;
  ticketsState: SideViewTicketState;
  setTicketsStateHandler: React.Dispatch<React.SetStateAction<SideViewTicketState>>;
}

export const EditTicketsView = ({
  onTicketClick,
  selectedTicketId,
  activeDrodpdownIndex,
  setActiveDrodpdownIndex,
  ticketsState,
  setTicketsStateHandler
}: Props) => {
  const {t} = useTranslation();
  const observer = useRef<IntersectionObserver>();
  const isMount = useRef<boolean>();
  const [search, setSearch] = useState('');

  const lastTicketElementRef = useCallback(
    node => {
      
      if (ticketsState.loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && ticketsState.hasMore) {
          setTicketsStateHandler(pre => ({ ...pre, pageNumber: pre.pageNumber + 1 }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [ticketsState.loading, ticketsState.hasMore]
  );

  const debounceSearch = useCallback(
    _.debounce((_searchText: string) => {
      setTicketsStateHandler(pre => ({
        ...pre,
        pageNumber: 1,
        tickets: [],
        searchText: _searchText
      }));
    }, 500), []
  );

  useEffect(() => {
    if (isMount.current) debounceSearch(search);
    isMount.current = true;
  }, [search]);

  const onCardClick = useCallback((id: number) => {
    onTicketClick(id);
  }, [selectedTicketId, ticketsState]);

  return (
    <div className={`h-full p-4 ${Styles.EditTicket} hidden md:block`}>
      <div className={Styles.InputsContainer}>
        <Flex className="mb-3" hAlign="end">
          <Dropdown style={{ cursor: 'pointer' }}
            inline
            items={Object.values(listInputItems)}
            triggerButton={
              <span className="font-semibold">
                {listInputItems[activeDrodpdownIndex]}
              </span>
            }
            placeholder={t('ticket-details.edit-ticket-view.dropdown-placeholder')}
            onChange={useCallback((_event: any, props: DropdownProps) => {
              setActiveDrodpdownIndex(props.highlightedIndex);
              setTicketsStateHandler(pre => ({ ...pre, pageNumber: 1, tickets: [] }));
            }, [activeDrodpdownIndex, ticketsState])}
          />
        </Flex>
        <Input
          placeholder={t('ticket-details.edit-ticket-view.search-placeholder')}
          icon={<SearchIcon />}
          iconPosition="end"
          inverted
          fluid
          defaultValue={search}
          onChange={useCallback((_: any, props: any) => { setSearch(props.value) }, [search, ticketsState])}
        />
      </div>
      <div style={{ height: 'calc(100vh - 190px)' }} className="overflow-auto">
        {ticketsState.tickets.length > 0 &&
          ticketsState.tickets.map((ticket: Ticket, index: number) =>
            ticketsState.tickets.length === index + 1 ? (
              <div key={ticket.Id} ref={lastTicketElementRef}>
                <TicketCard
                  key={ticket.Id}
                  ticket={ticket}
                  selectedTicketId={selectedTicketId}
                  onClick={onCardClick}
                />
              </div>
            ) : (
              <div key={ticket.Id}>
                <TicketCard
                  ticket={ticket}
                  selectedTicketId={selectedTicketId}
                  onClick={onCardClick}
                />
              </div>
            )
          )}
        {ticketsState.loading && (
          <div>
            <TicketCardSkeleton />
            <TicketCardSkeleton />
            <TicketCardSkeleton />
          </div>
        )}
        {!ticketsState.loading && ticketsState.tickets.length === 0 && (
          <EmptyData
            SVGIcon={<FlashlightSVG width={110} height={110} className={'mb-4'} />}
            headerText={t('ticket-details.edit-ticket-view.empty.header-text')}
            subheaderText={t('ticket-details.edit-ticket-view.empty.sub-header-text')}
          />
        )}
      </div>
    </div>
  );
};
