import axios, { AxiosInstance } from 'axios';
import { Providers } from '@microsoft/mgt-element';
import { Guid } from './guid.service';
import { TicketTemplate } from '../interfaces/ticket.interface';
import _ from 'lodash';
import { RelationType } from '../components/EditTicketRelateTickets/RelateTicketHelper';
import { ITeam } from '../interfaces/supportGroup.interface';
import { ILifecycle } from '../interfaces/lifecycle.interface';
import { Lifecycle } from '../../lifecycle/utils/typings';

import { ENTITY_TYPE, TicketSlaState } from '../utils/constants';


export interface ODataChangeRequest {
  requestUri: string;
  method: string;
  data: string;
}

export class platformService {
  api_token: string | null;
  client: AxiosInstance | null;
  api_url: string | undefined;
  constructor() {
    this.api_url = `${window.__runtimeConfig.platformurl}`;
    this.client = null;
  }

  static refreshAccessToken = _.debounce(
    async () => {
      try {
        await Providers.globalProvider.getAccessToken();
      } catch { }
    },
    30000,
    { leading: false, trailing: true }
  );
  
  init = (path: string = 'api/', customHeaders?, customConfig?) => {
    const token = localStorage.getItem('token') || localStorage.getItem(`msal.${window.__runtimeConfig.msalClientId}.idtoken`);
    if (!token) Providers.globalProvider.login();

    let headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*'
    };
    headers = Object.assign(headers, customHeaders);

    let config = {
      baseURL: `${this.api_url}${path}`,
      timeout: 300000,
      headers: headers
    };
    config = Object.assign(config, customConfig);

    this.client = axios.create(config);

    this.client.interceptors.response.use(
      response => {
        // Attempt to refresh the token on a successful call, if it falls within the window
        platformService.refreshAccessToken();
        return response;
      }, 
      async (error: any) => {
        if (error.response && error.response.status && 401 === error.response.status) {
          try {
            await Providers.globalProvider.getAccessToken(null);
            const newToken = localStorage.getItem('token') || localStorage.getItem(`msal.${window.__runtimeConfig.msalClientId}.idtoken`);
            const originalRequest = error.config;
            originalRequest.headers.Authorization = `Bearer ${newToken}`;

            return this.client.request(originalRequest);
          } catch {
            Providers.globalProvider.login();
          }
        } 
          
        return Promise.reject(error);
      }
    );

    this.client.interceptors.response.use(
      async response => {
        if (response.data && response.data['@odata.nextLink']) {
          const url = new URL(response.config.url, response.config.baseURL);
          const skipped = url.searchParams.get('$skip')
          url.searchParams.set("$skip", response.data.value.length + (skipped ? parseInt(skipped, 10) : 0));
          const req = {
            ...response.config,
            url: url.toString()
          };
          const rsp = await this.client.request(req);
          response.data.value = response.data.value.concat(rsp.data.value);
        }
        return response;
      },
      error => Promise.reject(error)
    );
    return this.client;
  };

  batch = (batchGuid: string, batchTimeout?: number) => {
    const token =
      localStorage.getItem('token') ||
      localStorage.getItem(
        `msal.${window.__runtimeConfig.msalClientId}.idtoken`
      );
    if (!token) Providers.globalProvider.login();
    let headers = {
      Accept: `multipart/mixed`,
      'Content-Type': `multipart/mixed; boundary=${batchGuid}`,
      Authorization: `Bearer ${token}`,
      'OData-Version': '4.0',
      'OData-MaxVersion': '4.0'
    };
    this.client = axios.create({
      baseURL: `${this.api_url}api/`,
      timeout: batchTimeout || 31000,
      headers: headers
    });
    this.client.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error: any) {
        if (401 === error.response.status) {
          Providers.globalProvider.login();
        } else {
          return Promise.reject(error);
        }
      }
    );
    return this.client;
  };

  getUserList = async (params?: string) => {
    const rsp = await this.init().get(`PlatformUser/$count`);
    const total = rsp.data;

    const chunks = Math.ceil(total / 500.0);
    const promises = [];
  
    for (let i = 0; i < chunks; i++) {
      const skip = i * 500;
      promises.push(this.init().get(`PlatformUser?$skip=${skip}&$top=500&$orderby=Id asc`));
    }

    const results = await Promise.all(promises);
    return results.reduce((total, current) => total.concat(current.data.value), []);
  };

  getTicketList = (params?: string) => {
    return this.init().get(`Ticket${params || ''}`);
  };
  getMyWorkListData = (params?: string) => {
    return this.init().get(`GetMyWork${params || ''}`);
  }

  getAffectedUsers = (params?: string) => {
    return this.init().get(`AffectedUser${params || ''}`);
  };

  getCollaborators = (params?: string) => {
    return this.init().get(`TicketCollaborator${params || ''}`);
  };

  getTagPromise = (params?: string) => {
    return this.init().get(`Tag${params || ''}`);
  };

  getTags = async (params?: string) => {
    try {
      const result = (await this.getTagPromise(params))?.data?.value ?? [];
      result.forEach((tag: any) => {
        tag.header = tag.Name;
        tag.accessibilityitemprops = tag.Id;
        tag.selected = false;
      });
      return result;
    } catch (e) {
      if (e['response']?.status === 402) return [];
      throw e;
    }
  };
  
  getSLAState = (params?: string) => {
    return this.init().get(`SlaState${params || ''}`);
  };

  getUrgency = (params?: string) => {
    return this.init().get(`Urgency${params || ''}`);
  };

  getImpact = (params?: string) => {
    return this.init().get(`Impact${params || ''}`);
  };

  getStatus = (params?: string) => {
    return this.init().get(`Status${params || ''}`);
  };

  getDefaultInstance = (params?: string) => {
    return this.init().get(
      `Status/Action.GetDefaultInstance${params || ''}`
    );
  };

  createStatus = (data: StatusDetail) => {
    return this.init().post(`Status`, data);
  };

  updateStatus = (data: UpdateStatusPayload) => {
    return this.init().patch(`Status(${parseInt(data.Id)})`, data);
  };

  getCategories = (params?: string) => {
    return this.init().get(`Category${params || ''}`);
  };

  getCategoryDefaultInstance = (params?: string) => {
    return this.init().get(
      `Category/Action.GetDefaultInstance${params || ''}`
    );
  };

  createCategory = (data: CategoryDetail) => {
    return this.init().post(`Category`, data);
  };

  updateCategory = (data: UpdateCategoryPayload) => {
    return this.init().patch(`Category(${parseInt(data.Id)})`, data);
  };

  getTicketTypes = (params?: string) => {
    return this.init().get(`TicketType${params || ''}`);
  };
  getTicketTypeSafe = async (id: number, params?: string) => {
    try {
      const result = await this.init().get(`TicketType(${id})${params || ''}`);
      return result.data;
    } catch (e: any) {
      if (e['response']?.status === 402) return null;
      throw e;
    }
  }
  getTicketTypesSafe = async (params?: string) => {
    try {
      const result = await this.init().get(`TicketType${params || ''}`);
      return result.data.value;
    } catch (e: any) {
      if (e['response']?.status === 402) return [];
      throw e;
    }
  };

  getTicketTypeDefaultInstance = (params?: string) => {
    return this.init().get(
      `TicketType/Action.GetDefaultInstance${params || ''}`
    );
  };

  createTicketType = (data: TicketTypeDetail) => {
    return this.init().post(`TicketType`, data);
  };

  updateTicketType = (data: UpdateTicketTypePayload) => {
    return this.init().patch(`TicketType(${parseInt(data.Id)})`, data);
  };

  getPriority = (params?: string) => {
    return this.init().get(`Priority${params || ''}`);
  };

  getSource = (params?: string) => {
    return this.init().get(`Source${params || ''}`);
  };

  getLifecycleStatus = (params?: string) => {
    return this.init().get(`LifecycleStatus${params || ''}`);
  };

  getPriorityDefaultInstance = (params?: string) => {
    return this.init().get(
      `Priority/Action.GetDefaultInstance${params || ''}`
    );
  };

  createPriority = (data: PriorityDetail) => {
    return this.init().post(`Priority`, data);
  };

  updatePriority = (data: UpdatePriorityPayload) => {
    return this.init().patch(`Priority(${parseInt(data.Id)})`, data);
  };

  updateTicket = (id: number | null, data?: {}) => {
    if (id == null) {
      throw new Error('id should not be null');
    } else {
      if (data.hasOwnProperty('TicketLifecycle')) {
        delete data['TicketLifecycle'];
        delete data['TicketLifecycleId'];
      }
      if (data.hasOwnProperty('Template')) 
        delete data['Template'];
      if (data.hasOwnProperty('Tags')) {
        delete data['Tags'];
      }
      return this.init().patch(`Ticket(${id})`, data);
    }
  };

  udpateTicketTags = (tid: number, tags:string) => {
    if (tid == null) {
      throw new Error('id should not be null');
    } else {
      return this.init().post(`Ticket(${tid})/Action.AddTags`, {
        Tags: tags
      });
    }
  };

  removeRefTags = (id: number, toDeleteTags: string) => {
    return this.init().post(`Ticket(${id})/Action.RemoveTags`, {
      Tags: toDeleteTags
    });
  };

  attachTagsOnTicket = (id: number | null, toRefTags?: number[], isTicketTemplate: boolean = false) => {
    if (id == null) {
      throw new Error('id should not be null');
    } else {
      let body = [];
      const changeSet = `changeset_${Guid.newGuid()}`;
      const batch = `batch_${Guid.newGuid()}`;
      body.push('--' + batch);
      body.push('Content-type: multipart/mixed; boundary=' + changeSet, '');

      let contentId = 1;

      toRefTags?.forEach(tagId => {
        body.push(`--${changeSet}`);
        body.push(`Content-Type: application/http`);
        body.push(`Content-Transfer-Encoding: binary`, '');
        
        if(!isTicketTemplate)
          body.push(`POST ~/api/Ticket(${id})/Tags/$ref HTTP/1.1`);
        else
          body.push(`POST ~/api/TicketTemplate(${id})/Tags/$ref HTTP/1.1`);

        contentId = this.setBatchBody(body, contentId, tagId);
      });
      body.push('--' + changeSet + '--', '');
      body.push('--' + batch + '--', '');

      let payload = body.join('\r\n');

      return this.batch(batch).post(`$batch`, payload);
    }
  };

  updateTicketAndRefTagsByBatch = (
    id: number | null,
    data?: {},
    toRefTags?: number[]
  ) => {
    if (id == null) {
      throw new Error('id should not be null');
    } else {

      const tagRefStringfiyData = JSON.stringify(data);
      const tagRefData = JSON.parse(tagRefStringfiyData);


      if (tagRefData.hasOwnProperty('Tags')) {
        delete tagRefData['Tags'];
      }

      if(tagRefData.hasOwnProperty('TicketCollaborators'))
        delete tagRefData['TicketCollaborators'];

      let body = [];
      const changeSet = `changeset_${Guid.newGuid()}`;
      const batch = `batch_${Guid.newGuid()}`;
      body.push('--' + batch);
      body.push('Content-type: multipart/mixed; boundary=' + changeSet, '');

      let contentId = 1;
      if (JSON.stringify(tagRefData) != '{}') {
        body.push(`--${changeSet}`);
        body.push(`Content-Type: application/http`);
        body.push(`Content-Transfer-Encoding: binary`, '');
        body.push(`PATCH ~/api/Ticket(${id}) HTTP/1.1`);
        body.push(`Content-ID: 1`);
        body.push(`Accept: application/json;q=0.9, */*;q=0.1`);
        body.push(`Content-Type: application/json;IEEE754Compatible=true`);
        body.push(`OData-Version: 4.0`, '');

        body.push(JSON.stringify(tagRefData));
        contentId = 2;
      }

      toRefTags.forEach(tagId => {
        body.push(`--${changeSet}`);
        body.push(`Content-Type: application/http`);
        body.push(`Content-Transfer-Encoding: binary`, '');
        body.push(`POST ~/api/Ticket(${id})/Tags/$ref HTTP/1.1`);
        contentId = this.setBatchBody(body, contentId, tagId);
      });
      body.push('--' + changeSet + '--', '');
      body.push('--' + batch + '--', '');

      let payload = body.join('\r\n');

      return this.batch(batch).post(`$batch`, payload);
    }
  };

  addComment = (data: { Body: string; IsPublic: boolean }) => {
    return this.init().post(`Comment`, data);
  };

  addCommentToTicket = (
    ticketId: number,
    data: { Body: string; IsPublic: boolean, ModifiedById?: number }
  ) => {
    return this.init().post(`Ticket(${ticketId})/Action.AddComment`, {
      Comment: data
    });
  };

  referenceComment = (tid: number, commentId: number) => {
    if (tid == null) {
      throw new Error('id should not be null');
    } else {
      return this.init().post(`Ticket(${tid})/Comments/$ref`, {
        '@odata.id': `http://local/api/Comment(${commentId})`
      });
    }
  };


  deleteTicket = (id: number | null) => {
    if (id == null) {
      throw new Error('id should not be null');
    } else {
      return this.init().delete(`Ticket(${id})`);
    }
  };

  getCommentsByTicket = (TicketId: number) => {
    return this.init().get(`Ticket(${TicketId})/Comments`);
  };

  getUsersInRole = () => {
    return this.init().get(`GetUsersInRole`);
  };

  GetMyApplicationRoles = () => {
    return this.init().get('GetMyApplicationRoles');
  };

  addTicket = (data: any) => {
    let payload = JSON.parse(JSON.stringify(data));
    if (data.Tags && data.Tags.length > 0) delete payload.Tags;
    if (data?.hasOwnProperty("AssigneeId") && data?.AssigneeId?.hasOwnProperty("length") && data?.AssigneeId?.length == 0) delete payload.AssigneeId;

    return this.init().post(`Ticket`, payload);
  };
  addRequest = (data: any) => {
    let payload = JSON.parse(JSON.stringify(data));
    if (data.Tags && data.Tags.length > 0)
      delete payload.Tags;

    return this.init().post(`AddTicket`, { Ticket: payload });
  }
  addRequestEmail = (data: any) => {
    let payload = JSON.parse(JSON.stringify(data));
    return this.init('email').post(`fluentTicket`, payload);
  }

  addTag = (data: any) => {
    return this.init().post(`Tag`, data);
  };

  updateTag = (id: number, data: { Name: string }) => {
    return this.init().patch(`Tag(${id})`, data);
  };

  getPlatformUserId = (data: any) => {
    return this.init().post(`GetPlatformUserId`, data);
  };

  postPlatformUserUser = (user: any) => {
    return this.init().post(`PlatformUser`, user);
  };

  getPlatformUserByFilter = (filter: any) => {
    return this.init().get(`PlatformUser?$filter=${encodeURI(filter)}`);
  };


  getOrCreatePlatformUser = async (user: any) => {
    let filter = '';
    if (user.UserName && user.UserName.length > 0)
      filter = `UserName eq '${user.UserName}'`;
    if (user.AadObjectId && user.AadObjectId.length > 0)
      filter += `${(filter.length > 0 ? ' or ' : '')}AadObjectId eq '${user.AadObjectId}'`;

    let platformUser = (await this.getPlatformUserByFilter(filter)).data.value;
    if (platformUser.length > 0) return platformUser[0];

    return (
      await this.postPlatformUserUser({
        Email: user.Email,
        GivenName: user.GivenName,
        FamilyName: user.FamilyName,
        UserName: user.UserName,
        AadObjectId: user.AadObjectId
      })
    ).data;
  };

  sendToConversation = async (data: any) => {
    return this.init().post('SendToConversation', data);
  };

  sendCustomFormToConversation = async (data: any) => {
    return this.init().post('SendCustomFormToConversation', data);
  };

  sendToTriageChannel = async (data: any) => {
    return this.init().post('SendToTriageChannel', data);
  };

  exportTicketsToCsv = async (filter: string) => {
    let fileResponse = await this.init().get(`${this.api_url}export/tickets/csv?${filter}`,  { responseType: 'blob' });
    let fileName = new Date(Date.now()).toLocaleString().replace(/[\/:,\s]/g, '');
    this.setupDownloadLink(`tickets-${fileName}.csv`, fileResponse);
  }

  getAiFile = async(container: string, fileName: string) => {
    let response = await this.init().get(`${this.api_url}getaifile?cn=${container}&fn=${fileName}`,  { responseType: 'blob' });
    this.setupDownloadLink(fileName, response);
  }

  setupDownloadLink = (fileName: string, response: any) => {
    let url = window.URL.createObjectURL(new Blob([response.data]));
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  getSystemSetting = (type: string) =>
    this.init().get(`Get_SystemSetting(TypeName='${type}')`);

  setSystemSetting = (type: string, data: any) => {
    const payload = {
      SystemSettings: {
        '@odata.type': `#${type}`,
        ...data
      }
    };
    return this.init().post(`Set_SystemSetting`, payload);
  };

  getTeamsBotConfiguration = () => this.init().get(`GetTeamsBotConfiguration`);
  getEmailConnectorConfiguration = () => this.init().get(`GetEmailConnectorConfiguration`);
  getDefaultTeamUnrestricted = () => this.init().get(`GetDefaultTeamUnrestricted`);
  getCultures = () => this.init().get('GetCultures');

  hasFeature = (feature: string) =>
    this.init().get(`HasFeature(FeatureName='${feature}')`);
  getLicenseFeatures = () => this.init().get(`GetAllLicenseFeatures`);
  getLicenseProducts = () => this.init().get(`GetAllLicenseProducts`);
  getTenantLicenses = () => this.init().get(`GetTenantLicenses`);

  installHelpBot = (data: any) => {
    return this.init().post(`InstallHelpBot`, data);
  };

  getGraphAccessToken = () => {
    return this.init().get(`graph-access-token`);
  };

  getTicket = (id: number | null, params?: string) => {
    if (id == null) {
      throw new Error('id should not be null');
    } else {
      return this.init().get(`Ticket(${id})${params || ''}`);
    }
  };

  getPlatformUser = (id: number | null, params?: string) => {
    if (id == null) {
      throw new Error('id should not be null');
    } else {
      return this.init().get(`PlatformUser(${id})${params || ''}`);
    }
  };

  getCardPayload = (
    id: number | null,
    bannerMessage: string = '',
    isTriage: boolean = false
  ) => {
    return this.init().get(
      `GetTicketAdaptiveCard(Id=${id},UserDate=${Date.now()},Message='${encodeURI(
        bannerMessage
      )}',IsTriage=${isTriage})`
    );
  };

  getSupportGroups = (params?: string) => {
    return this.init().get(`SupportGroup${params || ''}`);
  };

  updateSupportGroup = (type: string, id?: number | null, data?: {}) => {
    if (id === null || id === 0) {
      this.setSupportGroup(type, data);
    }

    return this.init().patch(`SupportGroup(${id})`, data);
  };

  updateSupportGroupMemmbers = (
    supportGroupId: number,
    memberIds: string[],
    name: string,
    description: string,
    channelId: string,
    teamsAadObjectId: string
  ) => {
    return this.init().post('UpdateSupportGroup', {
      SupportGroupId: supportGroupId,
      MemberAadObjectIds: memberIds,
      Name: name,
      Description: description,
      TeamsChannelId: channelId,
      TeamsAadObjectId: teamsAadObjectId
    });
  };

  setSupportGroup = (type: string, data: any) => {
    this.getSupportGroups(data.Id).then(response => {
      if (response.data.Id !== null && response.data.Id >= 0) return response;
    });

    const payload = {
      '@odata.type': `#${type}`,
      ...data
    };

    return this.init().post(`SupportGroup`, payload);
  };

  getRoleByName = (params: string) => {
    // Intentionally requires params
    return this.init().get(`KnownProviderRole${params}`);
  };

  syncUser = (userId: string) => {
    return this.init().get(`SyncUser(UserId=${userId})`);
  };

  syncAllUsers = () => {
    return this.init().get(`SyncAllUsers`);
  };

  addOrRemoveUserLicense = (userId: number, isConsumed: boolean) => {
      return this.init().get(`AddOrRemoveUserLicense(UserId=${userId},AddLicense=${!isConsumed})`);
  };

  updateUsersLicenses = (data: any) => {
    return this.init().post(`UpdateUserLicenses`, data);
  };

  getUser = (userId: string, params?: string) => {
    return this.init().get(`PlatformUser(${userId})${params || ''}`);
  };

  getUsers = (params?: string) => {
    if(params)
      return this.init().get(`PlatformUser${params || ''}`);

    return this.init().get(`PlatformUser?$orderby=FullName`);
  };

  getUserLicenses = (params?: string) => {
    return this.init().get(`UserProductLicense${params || ''}`);
  };
  
  createTag = (data: { Name: string }) => {
    return this.init().post('Tag', data);
  };

  deleteTag = (id: number) => {
    return this.init().delete(`Tag(${id})`);
  };

  //Ticket Actions

  takeTicket = (id: number | null) => {
    if (id == null) {
      throw new Error('id should not be null');
    } else {
      return this.init().post(`Ticket(${id})/Action.TakeTicket`);
    }
  };

  getTicketActivities = (
    entityId: number,
    top: number,
    skip: number,
    commentOnly: boolean,
    filter: string
  ) => {
    return this.init().get(
      `Ticket(${entityId})/Action.GetActivities?$top=${top}&$skip=${skip}&commentOnly=${commentOnly}&$filter=${filter}`
    );
  };

  meAuthenticate = () => this.init().get('meauthenticate');
  me = () => this.init().get('me');

  getSubscription = () => this.init('customer/').get('get-subscription');
  checkSubscription = () => this.init('customer/').get('check-subscription');
  getSubscriptionSchedule = () => this.init('customer/').get('get-subscription-schedule');
  getLicensedAnalyst = () => this.init('customer/').get('get-licensed-analyst');
  
  getLicenseSettings = () => this.init().get('GetLicenseSettings');
  getCheckoutLink = (successUri: string, cancelUri: string) =>
    this.init().post(`CreateCheckoutSession`, {
      SuccessUri: successUri,
      CancelUri: cancelUri
    });
  getPortalLink = (returnUri: string) => this.init().post(`CreatePortalSession`, { ReturnUri: returnUri });
  retryPayment = () => this.init().post(`RetryPayment`, {});
  cancelSubscription = () => this.init().post('CancelSubscription');
  updateSubscription = (
    customerId: string,
    paymentMethodId: string,
    priceId: string,
    quantity: number,
    address: any,
    email: string
  ) =>
    this.init('customer/').post('update-subscription', {
      paymentMethodId: paymentMethodId,
      customerId: customerId,
      priceId: priceId,
      quantity: quantity,
      email: email,
      address: address,
      emailOnly: priceId === ''
    });
  getInvoicePreview = (seatCount: number) => this.init('customer/').post('invoice-preview',  { seatCount: seatCount });
  getLatestInvoice = (invoiceId: string) => this.init('customer/').post('latest-invoice',  { invoiceId: invoiceId });
  /** Automations **/
  getAutomationList = (params?: string) => this.init().get(`Phase${params || ''}`);
  getAutomation = (phaseId: number) => this.init().get(`Phase(${phaseId})?$expand=Trigger,Steps($expand=Step)&$select=Id,Title,Status,Description,Enabled,TriggerId,Trigger,Steps`);
  getRuleStep = (stepId: number) => this.init().get(`Rule(${stepId})?$expand=EntitySections($expand=Groups($expand=ChildrenGroups, RuleCriteria))`);
  getEnumValues = (typeFullName: string) => this.init().get(`GetEnumByType(TypeFullName='${typeFullName}')`);
  getAutomationProperties = (entityName: string = ENTITY_TYPE.Ticket) => this.init().get(`GetAutomationProperties(EntityName='${entityName}')`);
  getEntityProperties = (entityName: string = ENTITY_TYPE.Ticket) => this.init().get(`GetEntityProperties(EntityName='${entityName}')`);
  getTriggerApplicableTypes = () => this.init().get('GetTriggerApplicableTypes');
  getGlobalRules = () => this.init().get('Rule?$filter=(true and (IsGlobal eq true))');
  getGlobalActions = () => this.init().get('Action?$filter=(true and (IsGlobal eq true))');
  deleteAutomation = (id: number) => this.init().delete(`Phase(${id})`);
  toggleAutomation = (id: number, data: ToggleAutomationPayload) => this.init().patch(`Phase(${id})`, data);
  getPropertyValuePair = (id: number) => this.init().get(`Action(${id})/PropertyValuePair`);
  getActionNotification = (id: number) => this.init().get(`Action(${id})/ActionNotification?$expand=NotifyUsers($select=Id,UserName,AadObjectId,FullName)`);
  getActionApplyTemplate = (id: number) => this.init().get(`Action(${id})/ActionApplyTemplate`);
  getEntitySections = (id: number) => this.init().get(`Rule(${id})/EntitySections`);
  getRuleEntitySectionGroups = (id: number) => this.init().get(`RuleEntitySection(${id})/Groups`);
  getRuleGroupChildrenGroups = (id: number) => this.init().get(`RuleGroup(${id})/ChildrenGroups`);
  getRuleGroupRuleCriteria = (id: number) => this.init().get(`RuleGroup(${id})/RuleCriteria`);
  createUpdateAutomation = (payload: AutomationCreatePayload) => this.init().post('SaveAutomationFlow', { Phase: JSON.stringify(payload) });

  getTicketFiles = async (ticketId: number) => {
    try {
      const result = await this.init().get(
        `Ticket(${ticketId})/Action.GetTicketAttachments`
      );
      return result.data.value;
    } catch {
      return [];
    }
  };

  uploadTicketFiles = (ticketId: number, files: any[], message: string, isPrivate: boolean, isEndUserPage: boolean, sendToChat?: boolean) => {
    let formData = new FormData();
    formData.append('ticketId', ticketId.toString());
    formData.append('message', message);
    formData.append('isPrivate', isPrivate ? 'true' : 'false');
    formData.append('isEndUserPage', isEndUserPage ? 'true' : 'false');
    formData.append('sendToChat', sendToChat ? 'true' : 'false');
    files.forEach(item => {
      formData.append(item.name, item.file ?? item);
    });
    return this.init('api/', { 'Content-Type': 'multipart/form-data' }, { timeout: 0 })
      .post('UploadTicketFiles', formData)
      .then(result => {
        let resultDto = result.data;
        if (resultDto['error'].length > 0) return [];
        else return JSON.parse(resultDto['data']);
      })
      .catch((e) => {
        return e;
      });
  };
  attachTicketFiles = async (ticketId: number | string, files: any[], message: string, isPrivate: boolean, isEndUserPage: boolean, sendToChat?: boolean) => {
    try {
      let ticket = ticketId;
      if (typeof ticketId === "string")
        ticket = parseInt(ticketId, 10);

      const result = await this.init().post('AttachFilesToTicket', { TicketId: ticket, Files: files, Message: message, IsPrivate: isPrivate, IsEndUser: isEndUserPage, SendToChat: sendToChat });
      let resultDto = result.data;
      return (resultDto['error'].length > 0) ? [] : JSON.parse(resultDto['data']);
    } catch (e) {
      return e;
    }
  };

  deleteFile = (id: number) => {
    return this.init().delete(`FileAttachment(${id})`);
  };

  getTicketTemplates = (params?: string) => {
    return this.init().get(`TicketTemplate${params || ''}`);
  };

  createTicketTemplate = (data: TicketTemplate) => {
    let payload = JSON.parse(JSON.stringify(data));
    if (data.Tags && data.Tags.length > 0) delete payload.Tags;
    if (data.AffectedUsers && data.AffectedUsers.length > 0) delete payload.AffectedUsers;
    if (data.Collaborators && data.Collaborators.length > 0) delete payload.Collaborators;

    return this.init().post('TicketTemplate', payload);
  };

  attachTemplateUsers = async (templateId: number, affected: number[], collabs: number[]): Promise<any> => {
    if (templateId == null) throw new Error('id should not be null');

    let body = [];
    const changeSet = `changeset_${Guid.newGuid()}`;
    const batch = `batch_${Guid.newGuid()}`;
    body.push('--' + batch);
    body.push('Content-type: multipart/mixed; boundary=' + changeSet, '');

    let contentId = 1;

    affected?.forEach(id => {
      body.push(`--${changeSet}`);
      body.push(`Content-Type: application/http`);
      body.push(`Content-Transfer-Encoding: binary`, '');

      body.push(`POST ~/api/TicketTemplate(${templateId})/AffectedUsers/$ref HTTP/1.1`);

      contentId = this.setBatchBody(body, contentId, id);
    });
    collabs?.forEach(id => {
      body.push(`--${changeSet}`);
      body.push(`Content-Type: application/http`);
      body.push(`Content-Transfer-Encoding: binary`, '');

      body.push(`POST ~/api/TicketTemplate(${templateId})/Collaborators/$ref HTTP/1.1`);

      contentId = this.setBatchBody(body, contentId, id);
    });

    body.push('--' + changeSet + '--', '');
    body.push('--' + batch + '--', '');

    let payload = body.join('\r\n');

    return this.batch(batch).post(`$batch`, payload);
  }

  updateTicketTemplate = (data: TicketTemplate) => {
    let payload = JSON.parse(JSON.stringify(data));
    delete payload["@odata.context"];
    
    let newData: any = {
      Tags: data.Tags,
      AffectedUsers: data.AffectedUsers?.map(u => u.Id) ?? [],
      Collaborators: data.Collaborators?.map(u => u.Id) ?? []
    };
    delete payload["Tags"];
    delete payload["AffectedUsers"];
    delete payload["Collaborators"];

    newData.TicketTemplate = payload;

    return this.init().post(`CommitTemplate`, newData);
  };

  deleteTicketTemplate = (id: number) => {
    return this.init().delete(`TicketTemplate(${id})`);
  };

  getApplicationRoles = () =>
    this.init().get('ApplicationRoles(IncludeAllCategories=true)');

  deleteMappedRole = (payload: MappedRolePayload) =>
    this.init().post('RemoveApplicationRoleMap', payload);

  batchSetPermission = () =>
    this.init().post('BatchSetPermissions', { Batch: [] });
  
  getTemplatesTicketCount = () => this.init().get('Ticket?$apply=groupby((TemplateId), aggregate($count as Count))');
  getTemplateCountByTicket = async () => {
    const { data: { value } } = await this.init().get('Ticket?$select=TemplateId&$filter=TemplateId ne null');
    const result = [];
    value.forEach((el: any) => {
      let idx = result.findIndex(x => x.TemplateId == el.TemplateId);
      if (idx === -1) result.push({ TemplateId: el.TemplateId, Count: 1 });
      else result[idx].Count++;
    });

    return result;
  };

  getAccessTokens = () => {
    return this.init().get(`RegisteredApiConnectionToken?$expand=Owner`);
  };

  getAccessToken = (id: string) => {
    return this.init().get(`RegisteredApiConnectionToken(${id})`);
  };

  deleteToken = (data: any) => {
    return this.init().post(`RemoveRemoteServiceToken`, data);
  };

  createToken = (data: any) => {
    return this.init().post(`CreateRemoteServiceToken`, data);
  };

  getMyApplicationRoleNames = async () => {
    let myApplicationRoles = (await this.init().get('GetMyApplicationRoles'))
      .data.value;
    return _.uniq(
      _.map(myApplicationRoles, function (r) {
        return r.RoleName;
      })
    );
  };


  getProviderRoleToApplicationRoleMap = async (params) => {
    return await this.init().get(`ProviderRoleToApplicationRoleMap${params || ''}`);
  }
  

  createMappedRole = async (data: MappedRolePayload[]) => {
    
    const body = [];
    const changeSet = `changeset_${Guid.newGuid()}`;
    const batch = `batch_${Guid.newGuid()}`;

    body.push('--' + batch);
    body.push('Content-type: multipart/mixed; boundary=' + changeSet, '');

    body.push(`--${changeSet}`);
    body.push(`Content-Type: application/http`);
    body.push(`Content-Transfer-Encoding: binary`, '');

    let i = 0;
    for (i; i < data.length; i++) {

      if (data[i].ApplicationRole=="Superagents")
      {
        data[i].ApplicationRole="Analysts";
      }
      
      body.push('POST ~/api/CreateApplicationRoleMap HTTP/1.1');
      body.push(`Content-ID: ${i + 1}`);
      body.push(`Accept: application/json;q=0.9, */*;q=0.1`);
      body.push(`Content-Type: application/json;IEEE754Compatible=true`);
      body.push(`OData-Version: 4.0`);
      body.push(`OData-MaxVersion: 4.0`, '');

      body.push(JSON.stringify(data[i]));
      body.push(`--${changeSet}`);
      body.push(`Content-Type: application/http`);
      body.push(`Content-Transfer-Encoding: binary`, '');
    }

    body.push('POST ~/api/BatchSetPermissions HTTP/1.1');
    body.push(`Content-ID: ${i + 1}`);
    body.push(`Accept: application/json;q=0.9, */*;q=0.1`);
    body.push(`Content-Type: application/json;IEEE754Compatible=true`);
    body.push(`OData-Version: 4.0`);
    body.push(`OData-MaxVersion: 4.0`, '');

    body.push(JSON.stringify({ Batch: [] }));

    body.push('--' + changeSet + '--', '');
    body.push('--' + batch + '--', '');

    let payload = body.join('\r\n');

    return this.batch(batch).post(`$batch`, payload);
  };

  updatePickerPositions = (
    url: string,
    data: UpdatePickerPositionPayload[]
  ) => {
    const body = [];
    const changeSet = `changeset_${Guid.newGuid()}`;
    const batch = `batch_${Guid.newGuid()}`;

    body.push('--' + batch);
    body.push('Content-type: multipart/mixed; boundary=' + changeSet, '');

    body.push(`--${changeSet}`);
    body.push(`Content-Type: application/http`);
    body.push(`Content-Transfer-Encoding: binary`, '');

    let i = 0;
    for (i; i < data.length; i++) {
      body.push(`PATCH ~/api/${url}(${data[i].Id}) HTTP/1.1`);
      body.push(`Content-ID: ${i + 1}`);
      body.push(`Accept: application/json;q=0.9, */*;q=0.1`);
      body.push(`Content-Type: application/json;IEEE754Compatible=true`);
      body.push(`OData-Version: 4.0`);
      body.push(`OData-MaxVersion: 4.0`, '');

      body.push(JSON.stringify(data[i]));
      if (i < data.length - 1) {
        body.push(`--${changeSet}`);
        body.push(`Content-Type: application/http`);
        body.push(`Content-Transfer-Encoding: binary`, '');
      }
    }

    body.push('--' + changeSet + '--', '');
    body.push('--' + batch + '--', '');

    let payload = body.join('\r\n');

    return this.batch(batch).post(`$batch`, payload);
  };

  updateBulkTickets = (data: BulkTicketEntity[], tagsRef: number[]) => {
    const body = [];
    const changeSet = `changeset_${Guid.newGuid()}`;
    const batch = `batch_${Guid.newGuid()}`;

    body.push('--' + batch);
    body.push('Content-type: multipart/mixed; boundary=' + changeSet, '');

    let i = 0;
    let contentId = 0;
    for (i; i < data.length; i++) {
      contentId = contentId + 1;
      body.push(`--${changeSet}`);
      body.push(`Content-Type: application/http`);
      body.push(`Content-Transfer-Encoding: binary`, '');
      body.push(`PATCH ~/api/Ticket(${data[i].Id}) HTTP/1.1`);
      body.push(`Content-ID: ${contentId}`);
      body.push(`Accept: application/json;q=0.9, */*;q=0.1`);
      body.push(`Content-Type: application/json;IEEE754Compatible=true`);
      body.push(`OData-Version: 4.0`, '');

      if (data[i].hasOwnProperty('Tags')) {
        delete data[i]['Tags'];
      }
      body.push(JSON.stringify(data[i]));

      if (tagsRef) {
        tagsRef.forEach(tagId => {
          contentId = contentId + 1;
          body.push(`--${changeSet}`);
          body.push(`Content-Type: application/http`);
          body.push(`Content-Transfer-Encoding: binary`, '');
          body.push(`POST ~/api/Ticket(${data[i].Id})/Tags/$ref HTTP/1.1`);
          body.push(`Content-ID: ${contentId}`);
          body.push(`Accept: application/json;q=0.9, */*;q=0.1`);
          body.push(`Content-Type: application/json;IEEE754Compatible=true`);
          body.push(`OData-Version: 4.0`);
          body.push(`OData-MaxVersion: 4.0`, '');
          body.push(`{"@odata.id":"http://local/api/Tag(${tagId})"}`);
        });
      }
    }

    body.push('--' + changeSet + '--', '');
    body.push('--' + batch + '--', '');

    let payload = body.join('\r\n');

    const timeout = (data.length > 2) ? (data.length / 2) * 40000 : 40000;
    return this.batch(batch, timeout).post(`$batch`, payload);
  };

  getMe = async (params?: string) => {
    return this.init().get(`me${params || ''}`);
  };

  relateTickets = async (data: any) => {
    return this.init().post('RelateTickets', data);
  };

  getRelatedTicketsIncludeDeleted = async (sourceTicketId: number) => {
    const result = await this.init().get(
      `Ticket(${sourceTicketId})/RelatedTickets`
    );
    return result.data.value;
  };

  getRelatedTickets = async (sourceTicketId: number) => {
    const result = await this.init().get(`RelatedTickets?$filter=IsDeleted%20eq%20false%20and%20SourceTicketId eq ${sourceTicketId} or RelatedTicketId eq ${sourceTicketId} 
    &$expand=relatedticket($select=Id,Title,StatusId),SourceTicket($select=Id,Title,StatusId) &$select=Id,RelationshipTypeId,CreatedById,CreatedDate&$orderby=Id desc`);
    return result.data.value;
  };

  getMergedSourceTicket = async (mergedTicketId: number) => {
    const result = await this.init()
      .get(`RelatedTickets?$filter=RelatedTicketId eq ${mergedTicketId} 
    and RelationshipTypeId eq ${RelationType.MergeTickets}`);
    return result.data.value[0];
  };

  getAllMergedTickets = async () => {
    try {
      const result = await this.init().get(
        `RelatedTickets?$filter=RelationshipTypeId eq ${RelationType.MergeTickets}`
      );
      return result.data.value;
    } catch {
      return [];
    }
  };

  getTicketComments = async (ticketIds: string) => {
    try {
      const result = await this.init().get(`Ticket?$filter=Id%20in%20(${ticketIds})&$expand=Comments($select=Body)&$select=Id`);
      return result.data.value;
    } catch {
      return [];
    }
  }
  getTicketCommentCount = async (ticketId: number) => {
    try {
      const result = await this.init().get(`Ticket(${ticketId})/Comments/$count`);
      return result.data;
    } catch {
      return 0;
    }
  }

  getSlaProperties = (entityName: string = ENTITY_TYPE.Ticket) =>
    this.init().get(`GetSlaProperties(EntityName='${entityName}')`);

  createSla = (body: SlaCreate) => {
    return this.init().post('CreateServiceLevelAgreement', body);
  };

  getSlaList = (params?: string) => this.init().get(`ServiceLevelAgreement${params || ''}`);
  
  getSla = (id: number, params?: string) => this.init().get(`ServiceLevelAgreement(${id})${params || ''}`);

  toggleSla = (id: number, isActive: boolean) => this.init().patch(`ServiceLevelAgreement(${id})`, { IsActive: isActive });

  deleteSla = (id: number) => this.init().delete(`ServiceLevelAgreement(${id})`);

  getTicketSla = async (_params?: string) => {
    try {
      const { data: { value } } = await this.init().get(`TicketSla${_params ? _params : ''}`);
      return value;
    } catch (err: any) {
      if (err['response']?.status === 402) return [];
      throw err;
    }
  };
  getTicketSlaCountBySla = async () => {
    const { data: { value } } = await this.init().get('TicketSla?$select=ServiceLevelAgreementId,TicketId&$expand=SlaState($select=Name)');
    const result = [];
    value.forEach((el: any) => {
      let idx = result.findIndex(x => x.SlaId == el.ServiceLevelAgreementId);
      if (idx === -1) 
        result.push({ SlaId: el.ServiceLevelAgreementId, TicketCount: 1, BreachedCount: el.SlaState.Name == TicketSlaState.Breached ? 1 : 0 });
      else {
        result[idx].TicketCount++;
        result[idx].BreachedCount += el.SlaState.Name == TicketSlaState.Breached ? 1 : 0
      }
    });

    return result;
  };

  getCustomViewsSafe = async (_params: string = '?$expand=ViewColumns,ViewFilters') => {
    try {
      const result = await this.init().get(`CustomView${_params ? _params : ''}`);
      return result?.data?.value || [];
    } catch (e) {
      if (e['response']?.status === 402) return [];
      throw e;
    }
  };

  createCustomView = (_body: CreateCustomView) => this.init().post('CustomView', _body);

  addOrUpdateCustomView = (customView) => {
    return this.init()
      .post('AddOrUpdateCustomView', customView)
      .then((result) => {
      const {IsError, Data} = result.data;

      if(IsError){
        return null
      }

      return JSON.parse(Data)      
    })
    .catch(() => {
      return null
    });
  }

  deleteCustomView = (viewId: number) => {
    return this.init().delete(`CustomView(${viewId})`);
  };
  
  getTeams = (params?: string) => {
    return this.init().get(`Team${params || ''}`);
  };

  getTeamSafe = async (id: number, params?: string) => {
    try {
      const result = await this.init().get(`Team(${id})${params || ''}`);
      return result.data;
    } catch (e: any) {
      if (e['response']?.status === 402) return null;
      throw e;
    }
  };
  getTeamsSafe = async (params?: string) => {
    try {
      const result = await this.init().get(`Team${params || ''}`);
      return result.data.value;
    } catch (e: any) {
      if (e['response']?.status === 402) return [];
      throw e;
    }
  };

  UpdateAffectedUsers = (id: number, affectedUserIds: any) => {
    return this.init().post(`Ticket(${id})/Action.UpdateAffectedUsers`, { AffectedUserIds: affectedUserIds });
  };

  UpdateCollaborators = (id: number, collaboratorIds: any) => {
    return this.init().post(`Ticket(${id})/Action.UpdateCollaborators`, { CollaboratorIds: collaboratorIds });
  };

  GetRequestsAndAffectedTickets = (id: number) => {
    return this.init().post(`GetRequestsAndAffectedTickets`, {
      UserPlatformId: id
    });
  };

  createTeam = (data: ITeam) => {
    return this.init().post('Team', data);
  };

  updateTeam = (data: ITeam) => {
    return this.init().patch(`Team(${data.Id})`, data);
  };

  getTicketLifecyclePhases = async (params?: string) => {
    try {
      const result = await this.init().get(`TicketLifecyclePhase${params || ''}`);
      return result.data.value;
    } catch (e) {
      if (e['response']?.status === 402) return [];
      throw e;
    }
  };
  deleteTicketLifecycle = async (lifecycleId: number) => {
    try {
      await this.init().delete(`TicketLifecycle(${lifecycleId})`);
    } catch (e) {
      if (e['response']?.status === 402) return [];
      throw e;
    }
  }

  getLifecycles = (params?: string) => {
    return this.init().get(`LifeCycle${params || ''}`);
  };

  getLifecyclesSafe = async (params?: string) => {
    try {
      const result = await this.init().get(`LifeCycle${params || ''}`);
      return result.data.value;
    } catch (e) {
      if (e['response']?.status === 402) return [];
      throw e;
    }
  };

  getLifecycleTransition = (params?: string) => {
    return this.init().get(`LifecycleTransition${params || ''}`);
  };

  getTransitionRule = (params?: string) => {
    return this.init().get(`TransitionRule${params || ''}`);
  };

  createLifecycle = (data: ILifecycle) => {
    return this.init().post(`LifeCycle`, data);
  };

  saveLifecycle = (data: Lifecycle) => {
    return this.init().post(`SaveLifecycleFlowSetting`, {LifecycleData: JSON.stringify(data)});
  };

  updateLifecycle = (id: number, data: ILifecycle) => {
    return this.init().patch(`LifeCycle(${id})`, data);
  };

  applyLifecycle = (ticketId: number, lifecycleId: number) => {
    return this.init().post(`ApplyLifecycleToTicket`, {TicketId: ticketId, LifecycleId: lifecycleId});
  };

  bulkEditTickets = (ticketIds: number[], changeProps: string) => {
    return this.init('api/', {}, { timeout: (ticketIds.length > 2) ? ticketIds.length * 20000 : 40000 }).post('BulkEditTickets', { TicketIds: ticketIds, ChangeProperties: changeProps });
  };

  addTicketApproval = async (data: Approval) => {
    return this.init().post('Approvals', data);
  };

  getTicketApprovals = async (ticketId: number) => {
    const result = await this.init()
      .get(`ticketapprovals?$filter=ticketid%20eq%20${ticketId}%20&$select=approval &$expand=approval($expand=Approvers($expand=Approver))`)
    return result.data.value;
  };

  getTicketApprovalsOfPhaseId = async (ticketId: number, activePhaseId) => {
    const result = await this.init()
      .get(`ticketapprovals?$filter=ticketid%20eq%20${ticketId}%20and%20approval/TicketLifecyclePhaseId%20eq%20${activePhaseId}&$select=approval &$expand=approval($expand=Approvers($expand=Approver))`)
    return result.data.value;
  };


  updateTicketApprover = async (data: any, id: number) => {
    return this.init().patch(`Approvers(${id})`, data);
  };

  updateTicketApproval = async (data: any, id: number) => {
    return this.init().patch(`Approvals(${id})`, data);
  };

  getApproval = async (id: number) => {
    const result = await this.init().get(`approvals?$filter=Id%20eq%20${id}`);
    return result.data.value;
  }

  getApprovalApprovers = async (id: number) => {
    const result = await this.init().get(`approvals?$expand=Approvers&$filter=Id%20eq%20${id}`);
    return result.data.value
  }

  getApprovalApproversTicketLifecyclePhase = async (id: number) => {
    const result = await this.init().get(`approvals?$expand=Approvers,TicketLifecyclePhase&$filter=Id%20eq%20${id}`);
    return result.data.value
  }

  getApprover = async (id: number) => {
    const result = await this.init().get(`approvers(${id})`);
    return result.data;
  }

  getAllApprovals = async () => {
    try {
      const result = await this.init().get(`ticketapprovals`);
      return result.data.value;
    } catch (e: any) {
      if (e['response']?.status === 402) return [];
      throw e;
    }
  }

  getApprovalsByUserId = async (platformUserId: number, isLifecyclesEnabled: boolean, ticketId: number = null, showAllApprovals: boolean=false) => {
    try {
      const ticketFilter = ticketId ? `TicketId eq ${ticketId}` : '';
      const approverFilter = showAllApprovals ? "" : `Approval/Approvers/any(a: a/Approver/Id eq ${platformUserId}) and `;
      const phaseFilter = `(Approval/TicketLifecyclePhaseId eq null ${isLifecyclesEnabled ? ' or Approval/TicketLifecyclePhase/IsCurrent eq true' : ''})`;
      const result = await this.init().get(`ticketapprovals?$expand=Ticket($expand=TicketLifecycle($expand=Status,Lifecycle($select=Title),Phases($filter=IsCurrent eq true))),Approval($expand=Approvers($expand=Approver),TicketLifecyclePhase)
      &$filter=${approverFilter}${ticketFilter} and ${phaseFilter} &$orderby=Approval/ModifiedDate desc`)
      return result.data.value;
    } catch (e) {
      if (e['response']?.status === 402) return [];
      throw e;
    }
  }
  getApproversByUser = async (userId: number, filter?: string) => {
    const result = await this.init().get(`RequestPortalApprovals${filter??''}`);
    return result.data;
  }
  
  getAllPendingTicketApprovals = async () => {
    try {
      const result = await this.init().get(`ticketapprovals?$expand=approval($filter=ApprovalState eq 3)`)
      return result.data.value;
    } catch (e) {
      if (e["response"]["status"] === 402) return [];
      throw e;
    }
  }
  getApprovalsByEmail = async (filter: string) => {
    const result = await this.init()
      .get(`ticketapprovals?$expand=Ticket,Approval($expand=Approvers($expand=Approver),TicketLifecyclePhase)&$filter=${filter}&$orderby=CreatedDate desc`)
    return result.data.value;
  };

  getKnowledgeAnswer = (question: string) => this.init().post(`GetKnowledgeAnswer`, { Question: question });
  getKnowledgeArticle = (qnaId: number) => this.init().get(`GetKnowledgeArticle(Id=${qnaId})`);
  
  createTicketTask = (data: any) => this.init().post(`TicketTask`, data);
  
  getTicketTasks = async (params?: string) => {
    const result = await this.init().get(`TicketTask${params || ''}`);
    return result.data.value;
  }

  deleteTicketTask = (params: string) => this.init().delete(`${params}`);
  
  updateTicketTask = (params: string, data) => this.init().patch(`${params}`, data);

  getTaskStatus = async (params?: string) => {
    try {
    const result = await this.init().get(`TaskStatus${params || ''}`);
    return result.data.value;
    } catch (e) {
    if (e['response']?.status === 402) return [];
    throw e;
    }
  };

  checkConsent = async (url: string) => await this.init().get(url);
  
  subscribeToEmail = async (aadId: string, email: string) => await this.init().post(`EmailSubscription`, { AadObjectId: aadId, EmailAddress: email });
  unsubscribeToEmail = async () => await this.init().post(`DisconnectEmailSubscription`);
  updateEmail = async (setting: any) => await this.init().post(`UpdateEmailSubscription`, { EmailConfig: setting });
  subscribeToEmailTeam = async (teamId: number, aadId: string, email: string) => await this.init().post(`Team(${teamId})/ConnectEmail`, { AadObjectId: aadId, EmailAddress: email });
  unsubscribeToEmailTeam = async (teamId: number) => await this.init().post(`Team(${teamId})/DisconnectEmail`);

  private setBatchBody(body: any[], contentId: number, tagId: number) {
    body.push(`Content-ID: ${contentId++}`);
    body.push(`Accept: application/json;q=0.9, */*;q=0.1`);
    body.push(`Content-Type: application/json;IEEE754Compatible=true`);
    body.push(`OData-Version: 4.0`);
    body.push(`OData-MaxVersion: 4.0`, '');
    body.push(`{"@odata.id":"http://local/api/Tag(${tagId})"}`);
    return contentId;
  }

  getTransitionsByCriteria = async(filter?: string) =>{
    const resultCriteria = await this.init().get(`TransitionRuleCriteria?filter=TransitionItemId%20eq%20${filter}%20and%20IsDeleted%20eq%20false`);
    const resultCriteriaData = resultCriteria.data.value;
    const transitionRuleGroupIds = resultCriteriaData.map(obj=>obj.TransitionRuleGroupId);
    
    const transitionGroups = [];
    for await (const resultGroupData of transitionRuleGroupIds.map(id => this.init().get(`TransitionRuleGroup?filter=Id%20eq%20${id}%20and%20IsDeleted%20eq%20false`))) {
      transitionGroups.push(resultGroupData.data.value[0])
    }

    const transitionRules = [];
    for await (const resultRuleData of transitionRuleGroupIds.map(id => this.init().get(`TransitionRule?filter=Id%20eq%20${id}%20and%20IsDeleted%20eq%20false`))) {
      transitionRules.push(resultRuleData.data.value[0])
    }

    const transitionIds = transitionRules.map(obj=>obj.TransitionId);
   
    return await this.getTransitionsById(transitionIds);
  }

  getTransitionsByTransitionAction = async(filter?: string) =>{
    const result = await this.init().get(`TransitionAction?$select=TransitionId&$filter=TransitionItemId%20eq%20${filter}%20and%20IsDeleted%20eq%20false`);
    const resultData = result.data.value;
    const transitionIds = resultData.map(obj=>obj.TransitionId);

    return await this.getTransitionsById(transitionIds);
  }

  getTransitionsById = async(transitionIds?: any[]) =>{
    const list = [];

    for await (const transitionResult of transitionIds.map(id => this.init().get(`LifecycleTransition?filter=Id%20eq%20${id}%20and%20IsDeleted%20eq%20false`))) {
      list.push(transitionResult.data.value[0])
    }

    return list;
  }

  getUploadUrl = async (ticketId: number | string, fileName: string, fileSize: number) => {
    let ticket = ticketId;
    if (typeof ticketId === "string")
      ticket = parseInt(ticketId, 10);
      
    return await this.init().post('CreateUploadSession', { TicketId: ticket, FileName: fileName, FileSize: fileSize });
  } 
  
}


