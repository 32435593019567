import React, { useEffect, useRef, useState } from 'react';
import {
  Dropdown,
  DropdownProps,
  FormLabel,
  Input,
  ProviderConsumer
} from '@fluentui/react-northstar';
import { platformService } from '../../services/platform.service';
import {
  FILTERS_STRINGS,
  AUTOMATION_CLOSE_VALUE,
  AutomationOperators,
  STATUS_GUID,
  SlaStateStrings,
  BooleanProp,
  ENTITY_TYPE
} from '../../utils/constants';
import { filterPlatformUsersInRole, getStatusIdByGuid } from '../../../tikit/ticketHelper';
import { appState } from '../../../AppState';
import { useTranslation } from 'react-i18next';
import { PeoplePicker, PersonType, UserType } from '@microsoft/mgt-react';
import MgtCss from '../../../../../src/components/mgt.module.css';
import { ThemeColorScheme } from '../../common/TeamsTheme';

interface Props {
  propertyValue: string;
  fieldOperatorShow: boolean;
  disableValueField: boolean;
  automationValue: string;
  showTextField: boolean;
  operatorValue: string;
  whenData?: AutomationWhenData;
  updateValue: (value: string) => void;
}

export const ValueField = ({
  propertyValue,
  fieldOperatorShow,
  disableValueField,
  automationValue,
  showTextField,
  operatorValue,
  whenData,
  updateValue
}: Props) => {
  const api = new platformService();
  const currentState = appState();
  const {t} = useTranslation();
  const peoplePickerRef = useRef<any>();
  const [automationValueList, setAutomationValueList] = useState<DropdownDataModel[]>([]);
  const [value, setValue] = useState('');
  const [isMultiple, setIsMultiple] = useState(false);
  const [forceTextField, setForceTextField] = useState(false);
  const [isPeoplePicker, setIsPeoplePicker] = useState(false);
  const [multipleValue, setMultipleValue] = useState([]);
  const [isOperatorLoaded, setIsOperatorLoaded] = useState(false);
  const [lastOperatorValue,setLastOperatorValue] = useState(operatorValue);

  const setValueField = (list, id: number, key: string = 'Value') => {
    const val = list && list.find(({ Id }) => Id === id);
    setValue(val !== undefined && val[key]);
  };

  const getDropdownProperties = (dropdownProperties, name) => {
    return dropdownProperties.map(item => {
      return {
        key: item[name],
        label: item[name],
        header: item[name],
        Id: item.Id
      };
    });
  };

  const getStatuses = async () => {
    return (await api.getStatus(FILTERS_STRINGS.ARCHIVE_FALSE)).data.value;
  }
  const getPriorities = async () => {
    return (await api.getPriority(FILTERS_STRINGS.ARCHIVE_FALSE)).data.value;
  }
  const getSources = async () => {
    return (await api.getSource(FILTERS_STRINGS.ARCHIVE_FALSE)).data.value;
  }
  const getCategories = async () => {
    return (await api.getCategories(FILTERS_STRINGS.ARCHIVE_FALSE)).data.value;
  }
  const getTicketTypes = async () => {
    return (await api.getTicketTypes(FILTERS_STRINGS.ARCHIVE_FALSE)).data.value;
  }
  const getSupportGroups = async () => {
    return (await api.getSupportGroups(FILTERS_STRINGS.FILTERS_URL_PARAMS)).data.value;
  }
  const getTeams = async () => {
    return (await api.getTeams(FILTERS_STRINGS.DELETE_FALSE)).data.value;
  }
  const getRequesters = async () => {
    return (await api.getUserList()).data.value;
  }
  const getAssignees = async () => {
    const results = await Promise.all([api.getUserList(), api.getUsersInRole()]);
    return { platformusers: results[0], usersInRole: JSON.parse(results[1].data.value) };
  }
  const getTicketTags = async () => {
    return (await api.getTags(FILTERS_STRINGS.DELETE_FALSE));
  }


  /** Value Data Fetcher Starts */
  const getAssigneeData = async (id: number) => {
    try {
      const results = (currentState?.platformusers?.length > 0 && currentState?.usersInRole?.length > 0) ?
        { platformusers: currentState.platformusers, usersInRole: currentState.usersInRole } : await getAssignees();
      const assigneeList = filterPlatformUsersInRole(results.platformusers, results.usersInRole);
      const automationValues: DropdownDataModel[] = assigneeList && getDropdownProperties(assigneeList, 'FullName');
      if (id > 0) setValueField(assigneeList, id, 'FullName');
      setAutomationValueList(automationValues);
    } catch (error) {
      console.error(error);
    }
  };

  const getCategoryData = async (id: number) => {
    try {
      const categoryList = currentState?.categories?.length > 0 ? currentState.categories : await getCategories();
      const automationValues: DropdownDataModel[] = categoryList && getDropdownProperties(categoryList, 'Value');
      if (id > 0) setValueField(categoryList, id);
      setAutomationValueList(automationValues);
    } catch (error) {
      console.error(error);
    }
  };

  const getTicketTypeData = async (id: number) => {
    try {
      const ticketTypeList = currentState?.ticketTypes?.length > 0 ? currentState.ticketTypes : await getTicketTypes();
      const automationValues: DropdownDataModel[] = ticketTypeList && getDropdownProperties(ticketTypeList, 'Value');
      if (id > 0) setValueField(ticketTypeList, id);
      setAutomationValueList(automationValues);
    } catch (error) {
      console.error(error);
    }
  };

  const getTicketTagData = async (id: number) => {
    try {
      const ticketTagList:any[] = currentState?.tags?.length > 0 ? currentState.tags : await getTicketTags();
      let test = ticketTagList.filter(x=>automationValue.includes(x.Id));
      setMultipleValue(getDropdownProperties(test, 'Name'));
      const automationValues: DropdownDataModel[] = ticketTagList && getDropdownProperties(ticketTagList, 'Name');
      if (id > 0) setValueField(ticketTagList, id, 'Name');
      setAutomationValueList(automationValues);
    } catch (error) {
      console.error(error);
    }
  };
  const getCollaboratorData = async (id: number) => {
    try {
      const results = (currentState?.platformusers?.length > 0 && currentState?.usersInRole?.length > 0) ? { platformusers: currentState.platformusers, usersInRole: currentState.usersInRole } : await getAssignees();
      const assigneeList = filterPlatformUsersInRole(results.platformusers, results.usersInRole);

      const testValue = `,${automationValue},`
      setMultipleValue(getDropdownProperties(assigneeList.filter(x => testValue.includes(`,${x.Id},`)), 'FullName'));

      const automationValues: DropdownDataModel[] = assigneeList && getDropdownProperties(assigneeList, 'FullName');
      if (id > 0) setValueField(assigneeList, id, 'FullName');
      setAutomationValueList(automationValues);
    } catch (error) {
      console.error(error);
    }
  };
  const getAffectedUserData = async (id: number) => {
    try {
      const requesterList = (currentState?.platformusers?.length > 0) ? currentState.platformusers : await getRequesters();
      
      const testValue = `,${automationValue},`
      setMultipleValue(getDropdownProperties(requesterList.filter(x => testValue.includes(`,${x.Id},`)), 'FullName'));
      
      const automationValues: DropdownDataModel[] = requesterList && getDropdownProperties(requesterList, 'FullName');
      if (id > 0) setValueField(requesterList, id, 'FullName');
      setAutomationValueList(automationValues);
    } catch (error) {
      console.error(error);
    }
  };

  const getSLAStateData = async (id: number) => {
    try {
      
      const slaStateList:any[] = (await api.getSLAState(FILTERS_STRINGS.DELETE_FALSE)).data.value;
      slaStateList.forEach(s => s.Name = SlaStateStrings[s.Name]);

      const automationValues: DropdownDataModel[] = slaStateList && getDropdownProperties(slaStateList, 'Name');

      if (id > 0) setValueField(slaStateList, id, "Name");
      setAutomationValueList(automationValues);
    } catch (error) {
      console.error(error);
    }
  };

  const getSLAListData = async (id: number) => {
    try {
      
      const slaStateList:any[] = (await api.getSlaList(FILTERS_STRINGS.DELETE_FALSE)).data.value;
      const automationValues: DropdownDataModel[] = slaStateList && getDropdownProperties(slaStateList, 'Name');

      if (id > 0) setValueField(slaStateList, id, "Name");
      setAutomationValueList(automationValues);
    } catch (error) {
      console.error(error);
    }
  };

  const getApprovalStateListData = async (id: number) => {
    try {
      
      const stateList = [
        {Id: 1, Name: t('ticket-details.edit-ticket-form.approval.approved')},
        {Id: 2, Name: t('ticket-details.edit-ticket-form.approval.rejected')},
        {Id: 3, Name: t('ticket-details.edit-ticket-form.approval.pending')}
      ];
      const automationValues: DropdownDataModel[] = getDropdownProperties(stateList, 'Name');

      if (id > 0) setValueField(stateList, id, "Name");
      setAutomationValueList(automationValues);
    } catch (error) {
      console.error(error);
    }

  }

  const getTemplateListData = async (id: number) => {
    try {
      
      const templateList:any[] = (await api.getTicketTemplates(FILTERS_STRINGS.DELETE_FALSE)).data.value;
      const automationValues: DropdownDataModel[] = templateList && getDropdownProperties(templateList, 'Name');

      if (id > 0) setValueField(templateList, id, "Name");
      setAutomationValueList(automationValues);
    } catch (error) {
      console.error(error);
    }
  };

  const getLifecycleData = async (id: number) => {
    try {
      
      const templateList:any[] = (await api.getLifecycles(FILTERS_STRINGS.DELETE_FALSE)).data.value;
      const automationValues: DropdownDataModel[] = templateList && getDropdownProperties(templateList, 'Title');

      if (id > 0) setValueField(templateList, id, "Title");
      setAutomationValueList(automationValues);
    } catch (error) {
      console.error(error);
    }
  };

  

  

  

  const getBooleanData = (_id: number) => {
    setAutomationValueList(AUTOMATION_CLOSE_VALUE);
  };

  const getGroupData = async (id: number) => {
    try {
      const groupList = currentState?.supportgroups?.length > 0 ? currentState.supportgroups : await getSupportGroups();
      const automationValues: DropdownDataModel[] = groupList && getDropdownProperties(groupList, 'Name');
      if (id > 0) setValueField(groupList, id, 'Name');
      setAutomationValueList(automationValues);
    } catch (error) {
      console.error(error);
    }
  };

  const getTeamData = async (id: number) => {
    try {
      const teamList = currentState?.teams?.length > 0 ? currentState.teams : await getTeams();
      const automationValues: DropdownDataModel[] = teamList && getDropdownProperties(teamList, 'FriendlyName');
      if (id > 0) setValueField(teamList, id, 'FriendlyName');
      setAutomationValueList(automationValues);
    } catch (error) {
      console.error(error);
    }
  };

  const getPriorityData = async (id: number) => {
    try {
      const priorityList = (currentState?.priority?.length > 0) ? currentState.priority : await getPriorities();
      const automationValues: DropdownDataModel[] = priorityList && getDropdownProperties(priorityList, 'Value');
      if (id > 0) setValueField(priorityList, id);
      setAutomationValueList(automationValues);
    } catch (error) {
      console.error(error);
    }
  };

  const getSourceData = async (id: number) => {
    try {

      let sourceList = [];
      if (whenData?.entityName==undefined || whenData.entityName==ENTITY_TYPE.Ticket){
        sourceList = (currentState?.source?.length > 0) ? currentState.source : await getSources();
        sourceList = sourceList.filter((item) => item.Value !== "Other");
      } 

      const automationValues: DropdownDataModel[] = sourceList && getDropdownProperties(sourceList, 'Value');
        if (id > 0) setValueField(sourceList, id);
        setAutomationValueList(automationValues);

    } catch (error) {
      console.error(error);
    }
  };

  const getRequesterData = async (id: number) => {
    try {
      const requesterList = (currentState?.platformusers?.length > 0) ? currentState.platformusers : await getRequesters();
      const automationValues: DropdownDataModel[] = requesterList && getDropdownProperties(requesterList, 'FullName');
      if (id > 0) setValueField(requesterList, id, 'FullName');
      setAutomationValueList(automationValues);
    } catch (error) {
      console.error(error);
    }
  };

  const getStatusData = async (id: number) => {
    getIFStatusData(id,true);
  };

  const getIFStatusData = async (id: number, excludeDeflected: boolean=false) => {
    try {
      let automationValues: DropdownDataModel[]=[];
      let statusList;
      if (whenData?.entityName==undefined || whenData.entityName==ENTITY_TYPE.Ticket)
      {
        const tempStatusList = (currentState?.ticketStatus?.length > 0) ? currentState.ticketStatus : await getStatuses();
        statusList = tempStatusList?.filter(((item: any) => item.Id != getStatusIdByGuid(tempStatusList, excludeDeflected ? STATUS_GUID.DEFLECTED : "00000000-0000-0000-0000-000000000000")));
        automationValues = statusList && getDropdownProperties(statusList, 'Value');
      }
      else if (whenData.entityName==ENTITY_TYPE.Task){
        statusList = (currentState?.taskStatus?.length > 0) ? currentState.taskStatus : await api.getTaskStatus();
        automationValues = statusList && getDropdownProperties(statusList, 'Value');
      }
      else if (whenData.entityName==ENTITY_TYPE.Lifecycle){
        statusList = (currentState?.lifecycleStatus?.length > 0) ? currentState.lifecycleStatus : await api.getLifecycleStatus();
        automationValues = statusList && getDropdownProperties(statusList, 'Value');
      }

      if (id > 0) setValueField(statusList, id);
      setAutomationValueList(automationValues);
    } catch (error) {
      console.error(error);
    }
  };

  const valueDataMapper = new Map([
    ['assignee', getAssigneeData],
    ['assignee_if', getAssigneeData],
    ['tickettype', getTicketTypeData],
    ['tickettype_if', getTicketTypeData],
    ['category', getCategoryData],
    ['category_if', getCategoryData],
    ['closed', getBooleanData],
    ['closed_if', getBooleanData],
    ['supportgroup', getGroupData],
    ['supportgroup_if', getGroupData],
    ['priority', getPriorityData],
    ['priority_if', getPriorityData],
    ['requester', getRequesterData],
    ['requester_if', getRequesterData],
    ['status', getStatusData],
    ['status_if', getIFStatusData],
    ['team', getTeamData],
    ['team_if', getTeamData],
    ['source', getSourceData],
    ['source_if', getSourceData],
    ['tags', getTicketTagData],
    ['tags_if',getTicketTagData],
    ['slastate_if',getSLAStateData],
    ['servicelevelagreement_if',getSLAListData],
    ['approvalstate_if',getApprovalStateListData],
    ['affectedusers', getAffectedUserData],
    ['affectedusers_if', getAffectedUserData],
    ['ticketcollaborators', getCollaboratorData],
    ['ticketcollaborators_if', getCollaboratorData],
    ['template_if',getTemplateListData],
    ['isrequiredbyall_if',getBooleanData],
    ['lifecycle_if',getLifecycleData],
    ['comments_if',getBooleanData]
  ]);

  const populateValueDropdown = (key: string, id: number) => {
    let action;
    if (commentIsPrivate()) action = valueDataMapper.get("comments_if");
    else action = valueDataMapper.get(key);
    
    action && action(id);
  };


  useEffect(() => {
    setIsMultiple(false);
    setForceTextField(false);

    setIsPeoplePicker(false);
    const hasNum = isNaN(+automationValue) ? 0 : +automationValue;
    if (hasNum === 0){
      setValue(
        BooleanProp.includes(propertyValue.toLowerCase())
          ? `${automationValue.charAt(0).toUpperCase()}${automationValue.slice(1)}` : automationValue
      );
    }

    if (!fieldOperatorShow && propertyValue !== '' && operatorValue === '') {
      populateValueDropdown(propertyValue.toLowerCase().removeWhiteSpaces(), hasNum);
    } else if (['tags', 'affectedusers', 'ticketcollaborators'].includes(propertyValue)) {
      setIsMultiple(true);
      populateValueDropdown(`${propertyValue}_if`, hasNum);
      setForceTextField([AutomationOperators.Contains.toLowerCase(), AutomationOperators.NotContains.toLowerCase()].includes(operatorValue.toLowerCase()));
    } else if (propertyValue=="approvers"){
      setIsPeoplePicker(true);
    } else if (propertyValue && operatorValue) {
      const postfix = (operatorValue === AutomationOperators.Equal || operatorValue === AutomationOperators.NotEqual) && 'if';
      populateValueDropdown(`${propertyValue.toLowerCase().removeWhiteSpaces()}_${postfix}`, hasNum);
    } else {
      setAutomationValueList([]);
    }
  }, [propertyValue, fieldOperatorShow, operatorValue, automationValue,peoplePickerRef.current]);

  useEffect(()=>{
    if (isPeoplePicker && peoplePickerRef.current && peoplePickerRef.current.selectedPeople.length<=0)
      {
        peoplePickerRef.current.selectedPeople = automationValue ? JSON.parse(automationValue).map((i: any) => { 
          const item = currentState.platformusers.find(u => u.UserName == i.userPrincipalName);
          return {
            displayName: item.FullName,
            userPrincipalName: item.UserName,
            id: item.AadObjectId
          };
        }) : [];
      }
  },[isPeoplePicker]);

  useEffect(()=>{
    if (['tags', 'affectedusers', 'ticketcollaborators'].includes(propertyValue)) {
      if (isOperatorLoaded) {
        let clearValue = true;
        if (lastOperatorValue != operatorValue) {
          const opt = [AutomationOperators.Contains.toLowerCase(), AutomationOperators.NotContains.toLowerCase()];
          if (opt.includes(lastOperatorValue.toLowerCase()) && opt.includes(operatorValue.toLowerCase())) {
            clearValue = false;
          } else {
            const opt = [AutomationOperators.Equal.toLowerCase(), AutomationOperators.NotEqual.toLowerCase(), AutomationOperators.Include.toLowerCase(), AutomationOperators.NotInclude.toLowerCase()];
            if (opt.includes(lastOperatorValue.toLowerCase()) && opt.includes(operatorValue.toLowerCase()))
              clearValue = false; 
          }
        }
        
        if (clearValue) 
          updateValue("");
      }
  
      setLastOperatorValue(operatorValue);
      setIsOperatorLoaded(true);
    }
  }, [operatorValue]);

  const automationValueChange = (_e, p: DropdownProps) => {
    let data:any = p.value as DropdownDataModel;
    
    if (data.constructor === Array) {
      data = data.map(i => i.Id).join(",");
      updateValue(data);
    } 
    else
    {
      updateValue(
        BooleanProp.includes(propertyValue.toLowerCase()) || commentIsPrivate()
          ? data.key
          : data.Id.toString()
      );
    }
  };

  const commentIsPrivate = () => propertyValue.toLowerCase()=="comments" && operatorValue==AutomationOperators.IsPrivate;

  const onSelectedUser = async (e: any) => {
    const selectedUsers = e.detail;
    let selectedUser = [];
    selectedUsers.forEach(user => {
      const userToMap = (user?.userPrincipalName) ? user.userPrincipalName : user.displayName
      selectedUser.push({
        displayName: user.displayName, 
        userPrincipalName: userToMap,
        id: user.id
      });
    });
    updateValue(JSON.stringify(selectedUser))
  };

  return (
    <ProviderConsumer render={globalTheme =>
      <div style={{ flex: '1' }}>
        <FormLabel content={t('automation.value-field.value')} styles={{ fontSize: '12px' }} />
        {showTextField || forceTextField ? (
          <Input
            inverted
            clearable
            fluid
            value={automationValue}
            onChange={e => {
              updateValue((e.target as HTMLInputElement).value);
            }}
            disabled={disableValueField}
            style={{ opacity: disableValueField ? '0.65' : 1 }}
          />
        ) : (
          <>
            {isPeoplePicker ? 
              (
                <div style={{
                  display: 'block',
                  ...{ '--input-background-color': 'var(--mgt-theme-background)' },
                  ...ThemeColorScheme(globalTheme.siteVariables)}}>
                  <PeoplePicker ref={peoplePickerRef} userType={UserType.user} type={PersonType.person} className={`width-med height-small ${MgtCss.groupsPicker}`} 
                        selectionChanged={onSelectedUser} 
                      />
                </div>
                )
              :
              <>
                {isMultiple && 
                  <Dropdown
                  disabled={disableValueField}
                  inverted
                  fluid
                  multiple
                  search
                  items={automationValueList}
                  placeholder={t('automation.value-field.select-a-Value')}
                  style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
                  value={multipleValue}
                  onChange={automationValueChange}
                />
                }
                
                {!isMultiple && 
                <Dropdown
                  disabled={disableValueField}
                  inverted
                  fluid
                  items={automationValueList}
                  placeholder={t('automation.value-field.select-a-Value')}
                  style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
                  value={value}
                  onChange={automationValueChange}
                />
                }
              </>
            }
            
          </>
        )}
      </div>
    } />
  );
};
